
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































.question-checkboxes-component {
  .el-form-item {
    margin-bottom: 12px;
  }

  .el-checkbox {
    align-items: center;
    background: $--jb-bg-primary-color;
    display: flex;
    flex-direction: row-reverse;
    font-size: $--font-size-base;
    font-weight: 600;
    height: 60px;
    justify-content: center;
    line-height: $--jb-icon-checkmark-size;
    margin: 0 0 16px 0;
    padding: $--jb-checkbox-padding;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;

    &__input {
      position: absolute;
      right: 22px;
      top: $--jb-icon-checkmark-size;
      transform: scale(2);
      border-color: inherit;

      &.is-focus .el-checkbox__inner {
        border-color: $--border-color-base;
      }
    }

    &__label {
      font-size: $--font-size-base;
      font-weight: 600;
      padding-left: $--jb-checkbox-label-padding-left;
      max-width: 80%;
      white-space: normal;
      margin-left: 0;
    }

    &:hover,
    &.is-checked {
      background: #f3f5fd;
      border-color: $--jb-light-primary-color;
      color: $--jb-light-primary-color;

      .el-checkbox__inner {
        border-color: $--jb-light-primary-color;
      }
    }

    &:hover,
    &:focus {
      transform: translateY(-3px);
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}
