
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





























































.question-slide-component {
  margin-bottom: 35px;
  text-align: left;

  &:last-child {
    margin-bottom: 32px;
  }

  .slide-item {
    margin: 0 24px 10px 24px;
  }

  .slide-label-cont {
    .label {
      font-size: 18px;
      font-weight: 600;
      color: $--clb-color__headings;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
      line-height: 24px;
      left: 0;
      position: relative;
    }

    .toggle {
      float: right;
      cursor: pointer;
    }
  }

  .vue-slider {
    padding: 12px 8px 40px 0 !important;

    .vue-slider-rail {
      background: #ececec;
      cursor: pointer;

      .vue-slider-mark-step {
        width: 1px;
        height: 8px;
        left: 50%;
        top: 20px;
        border-radius: 0;
      }

      .vue-slider-piecewise-dot {
        background: #cfcfcf;
        border-radius: 0;
        height: 95%;
        width: 39%;
        visibility: visible !important;
      }
    }

    &.vue-slider-ltr {
      .vue-slider-mark-label {
        margin-top: 20px;
      }

      .vue-slider-process {
        background: $--color-primary;
      }
    }

    .vue-slider-dot-tooltip-inner {
      border-color: $--jb-light-primary-color;
      background-color: $--jb-light-primary-color;
      line-height: 32px;
      padding: 4px 16px;
      font-size: 16px;
      font-weight: 600;

      @media (max-width: $--sm) {
        padding: 4px 8px;
        line-height: 28px;
        font-size: $--jb-font-size-small;
      }
    }
  }
}
