
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        










































































.list-complete-item {
  transition: all 1.5s ease-in;
}

.list-complete-enter,
.list-complete-leave-active {
  opacity: 0;
}

.question-order-component {
  .draggable-item {
    margin: 0 0 12px 0;
    position: relative;
    box-sizing: border-box;
    align-items: flex-start;
    background: #fff;
    border: 1px solid $--border-color-base;
    border-radius: $--border-radius-base;
    color: $--color-text-regular;
    font-weight: 600;
    padding: $--button-padding-vertical $--button-padding-horizontal;
    display: inline-block;
    line-height: 36px;

    .badge-item {
      box-sizing: border-box;
      color: $--border-color-base;
      height: 58px;
      left: 16px;
      line-height: 58px;
      position: absolute;
      top: 0;
      transition: color 0.3s ease;
    }

    &:hover {
      .badge-item {
        color: $--color-primary;
      }
    }

    &:focus,
    &:hover {
      cursor: move;
    }

    &:active,
    &:focus,
    &:hover {
      &:not(.sortable-chosen):not(.sortable-ghost) {
        background: #fff !important;
        border: 1px solid $--border-color-base !important;
        color: $--color-text-regular !important;

        .badge-item {
          color: $--border-color-base !important;
        }
      }
    }

    &.sortable-chosen,
    &.sortable-ghost {
      background: $--color-light !important;
      border-color: $--jb-light-primary-color !important;
      color: $--jb-light-primary-color !important;
    }
  }

  .ranking-icon-cont {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 13px;
    top: 13px;

    i {
      cursor: pointer;
    }
  }
}
