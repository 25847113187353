
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        














































































































.question-selectother-component {
  .form-item-input .el-input__inner {
    text-align: center;
  }
}
