
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






















































































































.question-phone-component {
  .form-item-input {
    display: inline-block;
    margin-right: 9.33%;
    text-align: center;
    width: 18%;

    .el-input__inner {
      text-align: center;
    }
  }

  .form-item-input::placeholder {
    text-align: center;
  }

  .form-item-input:last-child {
    margin-right: 0 !important;
  }

  .no-code {
    color: $--jb-survey-sub-header;
    font-size: $--jb-font-size-small;
    position: relative;
    top: 112px;
    height: 0;
  }

  .link {
    color: $--tooltip-color;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
