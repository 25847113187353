
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





























































































@import '~@jumbleberry/el-tel-input/dist/elTelInput.css';

.question-phone-components {
  .tel-input,
  .tel-input:hover {
    border: 1px solid $--color-primary;
    border-radius: 3px;
  }

  .el-input-group__prepend {
    background: $--jb-bg-primary-color;
    border: none;
    padding-left: 0;
    padding-right: 0;
  }

  .el-tel-input {
    .el-input__inner,
    .el-input__inner:hover {
      border: none;
      color: $--color-primary !important;
    }

    .el-input--prefix .el-input__inner {
      padding-left: 40px;
    }

    .el-input--prefix .el-input__prefix {
      padding-left: 10px;
    }

    .el-flagged-label {
      align-items: center;
      height: 100%;
    }

    .el-select {
      margin-left: 0;
      margin-right: 0;

      .el-input {
        width: 140px;

        .el-input__inner {
          border-radius: 0;
          border-right: 1px solid $--color-primary;
          padding: 15px 15px 15px 55px;
        }

        @media (max-width: $--sm) {
          width: 100px;

          .el-input__inner {
            padding-left: 15px;
          }

          .el-input__prefix {
            display: none;
          }
        }
      }
    }
  }
}
