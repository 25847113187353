
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        













































































































.question-select-component {
  button {
    line-height: 24px;
    margin-bottom: 12px;

    .align {
      display: inline-block;
      text-align: center;
      max-width: 80%;
      line-height: 17px;
      margin-left: 0;
    }

    .icon {
      left: -8px;
      line-height: 30px;
      margin-bottom: -6px;
      position: relative;
      transition: $--all-transition;
      fill: $--color-text-regular;
    }

    &.active .icon,
    &:hover .icon {
      fill: $--color-primary;
    }

    .el-icon-success {
      line-height: 24px;
      font-size: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}
