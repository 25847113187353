
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































































































































.survey-question-header {
  margin-bottom: 0;
  margin-top: 0;
}

.survey-question-sub-header {
  color: $--jb-survey-sub-header;
  font-size: $--jb-font-size-small;
  margin-bottom: 32px;
  margin-top: 8px;
}

.next-button {
  margin-top: 20px;
}
